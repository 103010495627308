import 'react-day-picker/dist/style.css';
import '../../resources/css/hotel-tab-index.scss';
import 'react-calendar/dist/Calendar.css';
import '../../resources/css/async-type-ahead.scss';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
// import { DialogBoxStore  } from '../../store/DialogBoxStore';

import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Calendar from 'react-calendar';
import { useNavigate } from 'react-router';
import { GalagoButton } from '../../components/Button';
import { FloatingIconFormControl } from '../../components/FormControl';
import { COLOR } from '../../custom-theme';
import { CalendarSvg } from '../../resources/svg/CalendarSvg';
import { CloseCircleFilledSvg } from '../../resources/svg/CloseSvg';
import { CircleGallySvg } from '../../resources/svg/GallySvg';
import { LocationSvg } from '../../resources/svg/LocationSvg';
import { SearchPageHook } from '../../hooks/SearchPageHook';
import { SearchSvg } from '../../resources/svg/SearchSvg';
import { PassengerSvg } from '../../resources/svg/PassengerSvg';
import { ChevronDownV2 } from '../../resources/svg/ChevronDownV2';
import { TiLocation } from 'react-icons/ti';
import { HotelSearchSvg } from '../../resources/svg/HotelSearchSvg';
import { hotelSearch } from '../../utils/apiRoutes';
import { AlertBox } from '../../components/AlertBox';
import { IoIosPaperPlane } from "react-icons/io";
import { HotelFormStore } from '../../store/HotelFormStore';

type HotelTabIndexProps = {
  reset?: boolean;
};

export const HotelTabIndex = ({ reset }: HotelTabIndexProps) => {
  const navigate = useNavigate();
  const { hotelLocations } = SearchPageHook();
  const destination = useRef<any>(null);
  const { hotels, setHotels, selectedHotel, setSelectedHotel, checkInDate, checkOutDate, setCheckInDate, setCheckOutDate, rooms, setRooms, adults, setAdults, children, setChildren }: any =
    HotelFormStore();

  // console.log(checkOutDate ,'asdasd')

  // const maxRooms = 9;
  const currentDate = moment(new Date()).format('MMM DD, YYYY');
  // const [defaultCheckInDate, setDefaultCheckInDate] = useState(currentDate);
  const [defaultCheckOutDate, setDefaultCheckOutDate] = useState('');
  // const [isHotelActive, setIsHotelActive] = useState(false);
  const [isCheckInActive, setIsCheckInActive] = useState(false);
  const [isCheckOutActive, setIsCheckOutActive] = useState(false);
  const [isPassengerActive, setIsPassengerActive] = useState(false);
  const [isPassengerChanged, setIsPassengerChanged] = useState(false);
  // const [maxGuestsCount, setMaxGuestCount] = useState(0);
  const [showCheckInDate, setShowCheckInDate] = useState(false);
  const [showCheckOutDate, setShowCheckOutDate] = useState(false);
  const [showPassengerForm, setShowPassengerForm] = useState(false);
  const checkInRef = useRef(null);
  // const { setDialogSize, setDialogTitle, setDialogMessage, setShowDialog } = DialogBoxStore();
  const [alertModal, setAlertModal] = useState(false);

  useEffect(() => {
    setDefaultCheckOutDate(moment(currentDate).add(1, 'days').format('MMM DD, YYYY'));
    if (checkInDate !== '') {
      setDefaultCheckOutDate(moment(checkInDate).add(1, 'days').format('MMM DD, YYYY'));
    }
    if (checkOutDate !== '') {
      if (moment(checkInDate).isSameOrAfter(moment(checkOutDate ? checkOutDate : defaultCheckOutDate))) {
        setCheckOutDate(moment(checkInDate).add(1, 'days').format('MMM DD, YYYY'));
      }
    }
    // eslint-disable-next-line
  }, [checkInDate]);

  //! Search Nearby Hotel
  const initialData = {
    index: 0,
    name: 'Search nearby hotel',
    isCity: true,
    address: '',
    hotelCode: '',
    fullLocation: 'Nearby Places',
  };

  const [mappedHotels, setMappedHotels] = useState([initialData]);
  const [locationCoords, setLocationCoords] = useState<{ latitude: number | null; longitude: number | null }>({
    latitude: 0,
    longitude: 0,
  });

  const [isInitialDataSelected, setIsInitialDataSelected] = useState(false);
  const [locationAccessDenied, setLocationAccessDenied] = useState(false);

  useEffect(() => {
    if (hotels !== undefined && hotels.tag !== undefined) {
      const foundHotels = hotels.tag;
      let _locationsAndHotels = [{ ...initialData }];
      let _i = 1; // Start from 1 since the initial data is already added

      foundHotels.forEach((location: any) => {
        if (location.location && location.fullLocation) {
          // Ensure the location has the necessary properties
          _locationsAndHotels[_i] = {
            index: _i,
            name: location.location,
            isCity: true,
            address: '',
            hotelCode: '',
            fullLocation: location.fullLocation,
          };
          _i++;

          if (location.hotels && Array.isArray(location.hotels)) {
            // Check if location.hotels is defined and an array
            location.hotels.forEach((hotel: any) => {
              if (hotel.HotelName && hotel.CityName && hotel.CountryName && hotel.HotelCode) {
                // Ensure hotel has the necessary properties
                _locationsAndHotels[_i] = {
                  index: _i,
                  name: hotel.HotelName,
                  isCity: false,
                  address: `${hotel.CityName}, ${hotel.CountryName}`,
                  hotelCode: hotel.HotelCode,
                  fullLocation: location.fullLocation,
                };
                _i++;
              }
            });
          }
        }
      });

      setMappedHotels(_locationsAndHotels);
    }
    // eslint-disable-next-line
  }, [hotels]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isInitialDataSelected && event.key === 'Backspace') {
        event.preventDefault();
      }
    };

    if (isInitialDataSelected) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isInitialDataSelected]);

  //* Logic for AutoSearch Nearby Hotel
  const handleSelectHotel = (e: any) => {
    const selectedIndex = e[0]?.index;
    const selectedHotel = mappedHotels[selectedIndex];
    setSelectedHotel(e[0]?.name);

    if (selectedHotel?.name === initialData?.name) {
      setIsInitialDataSelected(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // console.log('Latitude:', position.coords.latitude);
          // console.log('Longitude:', position.coords.longitude);
          setLocationCoords({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setLocationAccessDenied(false);
        },
        (error) => {
          // console.error('Error getting location:', error);
          if (error.code === error.PERMISSION_DENIED) {
            setLocationAccessDenied(true);
          }
        }
      );
    } else {
      setIsInitialDataSelected(false);
    }
  };

  let searchUrl: string = hotelSearch();
  searchUrl += `?&checkInDate=${checkInDate}&checkOutDate=${checkOutDate as string}&adults=${adults}&rooms=${rooms}&longitude=${locationCoords.longitude}&latitude=${locationCoords.latitude}`;

  //! GalaGO Alert And Request
  const handleButtonClick = () => {
    if (locationAccessDenied) {
      setAlertModal(true);
      return;
    } else {
      const payloadString = isInitialDataSelected
        ? `checkInDate=${checkInDate ? checkInDate : currentDate}&checkOutDate=${checkOutDate ? checkOutDate : defaultCheckOutDate}&adults=${adults}&rooms=${rooms}&latitude=${
            locationCoords.latitude
          }&longitude=${locationCoords.longitude}`
        : `keyword=${selectedHotel}&checkInDate=${checkInDate ? checkInDate : currentDate}&checkOutDate=${checkOutDate ? checkOutDate : defaultCheckOutDate}&adults=${adults}&rooms=${rooms}`;

      const finalSearchUrl = `${searchUrl}&${payloadString}`;

      navigate(`/hotel/results?${payloadString}`, {
        state: {
          searchUrl: finalSearchUrl,
        },
      });
    }
  };

  // search button logic
  const [disableButton, setDisabledButton] = useState(true);
  useEffect(() => {
    setDisabledButton(true);
    // change the border color of async-typeahead input text
    destination.current.inputNode.style.borderColor = '';
    if (selectedHotel) {
      destination.current.inputNode.style.borderColor = '#4FA2B4';
    }

    if (selectedHotel && (checkInDate || currentDate) && (checkOutDate || defaultCheckOutDate) && adults) {
      setDisabledButton(false);
    }

    // if (locationAccessDenied) {
    //   setDisabledButton(true);
    //   setAlertModal(true);
    // }
    // eslint-disable-next-line
  }, [selectedHotel, checkInDate, checkOutDate]);

  const updateRoomsAndAdultsCount = (count: number, triggeredFrom: string, operation?: string) => {
    if (count === 9 && triggeredFrom === 'room') {
      setRooms(9);
      setAdults(27);
      return;
    }

    if (triggeredFrom === 'adult' && count === 27) {
      setRooms(9);
      setAdults(27);
      return;
    }

    if (triggeredFrom === 'room') {
      if (operation === 'plus') {
        setAdults((prev: number) => prev + 1);
      } else {
        let adultsCount = adults;
        if (adultsCount >= 1) setAdults((prev: number) => prev - 1);
      }
    }
  };

  //* Render Logic Geolocation


  //* Increment adults when room is incremented 

  const incrementRoom = () => {
    let roomCount = rooms;

    // Increment room count if it's less than 9
    roomCount = roomCount === 9 ? 9 : ++roomCount;
    setRooms(roomCount);

    // Ensure at least one adult per room
    if (adults < roomCount) {
      setAdults(roomCount); // Set the number of adults to match room count
    }
  };

  const decrementRoom = () => {
    let roomCount = rooms;
    if (roomCount > 1) {
      roomCount--;
      setRooms(roomCount);
    }
  };

  


  return (
    <>
      <div className="tab-pane fade" id="hotel-tab-pane" role="tabpanel" aria-labelledby="hotel-tab">
        <div className="container py-lg-4 py-2 px-4 m-0">
          <div className="mb-3 hotel-mobile">
            <div className="position-relative w-100">
              <label htmlFor="async-hotel" className="form-label">
                Destination or Hotel
              </label>
              <span className="position-relative d-block" id="hotel-destination-holder">
                <LocationSvg _color={selectedHotel || isInitialDataSelected ? '#4FA2B4' : '#ADADAD'} className="location-svg" />
                {selectedHotel !== '' && (
                  <CloseCircleFilledSvg
                    _color={'#4FA2B4'}
                    className="clear-text-svg"
                    onClick={() => {
                      setSelectedHotel('');
                      setIsInitialDataSelected(false);
                      destination.current.state.text = '';
                      destination.current.state.selected = [];
                      destination.current.inputNode.value = '';
                    }}
                  />
                )}
                <AsyncTypeahead
                  ref={destination}
                  id="async-hotel"
                  placeholder="City or Property Name"
                  isLoading={false}
                  filterBy={() => true}
                  minLength={1}
                  delay={500}
                  onSearch={(query: string) => {
                    hotelLocations(query).then((response: { [index: string]: any }) => {
                      setHotels(response);
                    });
                  }}
                  onChange={handleSelectHotel}
                  onKeyDown={(e) => {
                    if (!/[a-zA-Z, ]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  labelKey={(option: any) => (option?.isCity ? option?.fullLocation : option?.name)}
                  options={mappedHotels}
                  renderMenuItemChildren={(value: any) => {
                    const isNearbyHotel = value?.name === 'Search nearby hotel';
                    return (
                      <div
                        style={{ padding: '10px' }}
                        className={!value?.isCity ? 'ps-3 d-flex gap-1.5 align-items-start generalsans-medium' : 'd-flex gap-1.5 align-items-baseline generalsans-medium'}
                      >
                        <div className={value?.isCity ? 'pt-0.2 flex align-baseline' : 'ps-3 flex align-baseline'}>
                          {isNearbyHotel ? <IoIosPaperPlane width={'11px'} height={'11px'} /> : value?.isCity ? <TiLocation /> : <HotelSearchSvg />}
                        </div>
                        <div className="d-flex flex-column gap-0.5">
                          <div>{isNearbyHotel ? 'Search nearby hotel' : value?.isCity ? value.fullLocation : value.name}</div>
                          <div className="leading-normal" style={{ fontSize: '14px', color: '#333333', fontWeight: '400', whiteSpace: 'normal' }}>
                            <span>{value.address}</span>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                />
              </span>
            </div>
            <div className="position-relative w-100" ref={checkInRef} onBlur={() => setIsCheckInActive(false)} onFocus={() => setIsCheckInActive(true)}>
              <FloatingIconFormControl
                onClick={(e: any) => {
                  setIsCheckInActive(true);
                  setShowCheckInDate(true);
                  setShowCheckOutDate(false);
                  setShowPassengerForm(false);
                }}
                value={checkInDate === '' ? currentDate : checkInDate}
                type={'text'}
                label="Check-in Date"
                placeholder={checkInDate === '' ? currentDate : checkInDate}
                icon={<CalendarSvg _color={isCheckInActive || selectedHotel || checkInDate ? '#4FA2B4' : '#ADADAD'} />}
                readOnly={true}
                style={{
                  border: isCheckInActive || selectedHotel || checkInDate ? '2px solid #4FA2B4' : '',
                  marginBottom: '10px',
                  cursor: 'pointer',
                }}
              />
              {showCheckInDate && (
                <div
                  onMouseLeave={() => {
                    setShowCheckInDate(false);
                  }}
                  className="hotel-calendar-holder"
                >
                  <Calendar
                    onChange={(e: any) => {
                      setShowCheckInDate(false);
                      setCheckInDate(moment(e).format('MMM DD, YYYY'));
                      setIsCheckOutActive(true);
                      setShowCheckOutDate(true);
                    }}
                    defaultValue={checkInDate ? new Date(checkInDate) : new Date(currentDate)}
                    minDate={new Date(currentDate)}
                    maxDate={new Date(moment().add(1, 'year').format('YYYY-MM-DD'))}
                  />
                </div>
              )}
            </div>
            {/* <div className="position-relative w-100" onClick={() => {if (checkOutDate) { setShowCheckOutDate(true); setShowCheckInDate(false); setShowPassengerForm(false);}}}> */}
            <div className="position-relative w-100">
              <FloatingIconFormControl
                onClick={() => {
                  setShowCheckOutDate(true);
                  setShowCheckInDate(false);
                  setShowPassengerForm(false);
                }}
                value={checkOutDate}
                type={'text'}
                label="Check-out Date"
                placeholder={checkOutDate === '' ? defaultCheckOutDate : checkOutDate}
                readOnly={true}
                onFocus={() => setIsCheckOutActive(true)}
                onBlur={() => setIsCheckOutActive(false)}
                style={{
                  border: isCheckOutActive || selectedHotel || checkOutDate ? '2px solid #4FA2B4' : '',
                  marginBottom: '10px',
                  cursor: 'pointer',
                }}
                icon={<CalendarSvg _color={isCheckOutActive || selectedHotel || checkOutDate ? '#4FA2B4' : '#ADADAD'} />}
              />
              {showCheckOutDate && (
                // <div>
                <div onMouseLeave={() => setShowCheckOutDate(false)} className="hotel-calendar-holder">
                  <Calendar
                    onChange={(e: any) => {
                      setShowCheckOutDate(false);
                      setCheckOutDate(moment(e).format('MMM DD, YYYY'));
                      // handleCheckOutDateChange(moment(e).format('MMM DD, YYYY'));
                    }}
                    value={checkOutDate ? new Date(checkOutDate) : new Date(defaultCheckOutDate)}
                    // minDate={new Date(moment(checkInDate).add(1, 'day').format('YYYY-MM-DD'))}
                    minDate={checkInDate ? new Date(checkInDate) : new Date()}
                    maxDate={new Date(moment(checkInDate).add(29, 'days').format('YYYY-MM-DD'))}
                  />
                </div>
              )}
            </div>

            <div className="position-relative w-100">
              <FloatingIconFormControl
                type="text"
                icon={<PassengerSvg _color={selectedHotel || isPassengerActive || isPassengerChanged ? '#4FA2B4' : '#ADADAD'} />}
                caret={
                  <ChevronDownV2
                    style={showPassengerForm ? { transform: 'rotate(180deg)', transition: 'transform 0.3s ease, fill 0.3s ease' } : { transition: 'transform 0.3s ease, fill 0.3s ease' }}
                    _color={selectedHotel || isPassengerActive || isPassengerChanged ? '#4fa2b4' : '#adadad'}
                  />
                }
                onClick={() => {
                  setShowPassengerForm(true);
                  setShowCheckOutDate(false);
                  setShowCheckInDate(false);
                }}
                onFocus={() => setIsPassengerActive(true)}
                onBlur={() => setIsPassengerActive(false)}
                value={`${adults} ${adults > 1 ? 'Adults' : 'Adult'}, ${children} ${children > 1 ? 'Children' : 'Child'}, ${rooms} ${rooms > 1 ? 'Rooms' : 'Room'}`}
                label={'Guests and Rooms'}
                style={{
                  border: selectedHotel || isPassengerActive || isPassengerChanged ? '2px solid #4FA2B4' : '',
                  marginBottom: '10px',
                  cursor: 'pointer',
                }}
                readOnly={true}
              />
              {showPassengerForm && (
                <div
                  id="passenger-details-holder"
                  className="position-absolute bg-body d-flex flex-column align-items-start justify-content-center"
                  style={{
                    left: 0,
                    right: 0,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '100%',
                    zIndex: 1,
                  }}
                  onMouseLeave={() => {
                    setShowPassengerForm(false);
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center align-self-stretch" style={{ padding: '8px 0px' }}>
                    <div className="gap-2 d-flex justify-content-center align-items-center">
                      <div className="passenger-count-label">
                        <span className="w-100 d-inline-block generalsans-medium">Rooms</span>
                        <span className="w-100 d-inline-block"></span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '12px',
                      }}
                    >
                      <button
                        className="passenger-count-btn"
                        disabled={rooms === 1 ? true : false}
                        onClick={() => {
                          // setRooms((prev) => prev - 1);
                          let roomCount = rooms;
                          setRooms(roomCount === 0 ? 0 : --roomCount);
                          updateRoomsAndAdultsCount(roomCount, 'room');
                          setIsPassengerChanged(true);
                        }}
                        style={{
                          borderColor: rooms === 1 ? 'transparent' : COLOR.primary,
                        }}
                      >
                        
                        -
                      </button>
                      <span style={{ width: '10px' }}>{rooms}</span>
                      <button
                        className="passenger-count-btn"
                        disabled={rooms === 9 ? true : false}
                        onClick={incrementRoom}
                        style={{
                          color: COLOR.primary,
                          borderColor: COLOR.primary,
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center align-self-stretch" style={{ padding: '8px 0px' }}>
                    <div className="gap-2 d-flex justify-content-center align-items-center">
                      <div className="passenger-count-label">
                        <span className="w-100 d-inline-block generalsans-medium">Adults</span>
                        <span className="w-100 d-inline-block generalsans-regular">Ages 18 and above</span>
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '12px',
                      }}
                    >
                      <button
                        className="passenger-count-btn adult-control"
                        disabled={adults === 1 ? true : false}
                        onClick={decrementRoom}
                        style={{
                          borderColor: adults === 1 ? 'transparent' : COLOR.primary,
                        }}
                      >
                        -
                      </button>
                      <span style={{ width: '10px' }}>{adults}</span>
                      <button
                        className="passenger-count-btn adult-control"
                        disabled={adults >= 27 ? true : false}
                        onClick={() => {
                          let adultCount = adults;
                          setAdults(adultCount === 27 ? 27 : ++adultCount);
                          updateRoomsAndAdultsCount(adultCount, 'adult', 'plus');
                          setIsPassengerChanged(true);
                        }}
                        style={{
                          // color: maxGuestsCount === maxRooms ? '#333' : COLOR.primary,
                          // borderColor: maxGuestsCount === maxRooms ? 'transparent' : COLOR.primary,
                          color: COLOR.primary,
                          borderColor: COLOR.primary,
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center align-self-stretch" style={{ padding: '8px 0px' }}>
                    <div className="gap-2 d-flex justify-content-center align-items-center">
                      <div className="passenger-count-label">
                        <span className="w-100 d-inline-block generalsans-medium">Children</span>
                        <span className="w-100 d-inline-block generalsans-regular">Ages 2 to 12</span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '12px',
                      }}
                    >
                      <button
                        className="passenger-count-btn"
                        disabled={children === 0 ? true : false}
                        onClick={() => {
                          let childrenCount = children;
                          setChildren(childrenCount === 0 ? 0 : --childrenCount);
                          updateRoomsAndAdultsCount(childrenCount, 'children');
                          setIsPassengerChanged(true);
                        }}
                        style={{
                          borderColor: children === 0 ? 'transparent' : COLOR.primary,
                        }}
                      >
                        -
                      </button>
                      <span style={{ width: '10px' }}>{children}</span>
                      <button
                        className="passenger-count-btn"
                        disabled={children === 9}
                        onClick={() => {
                          let childrenCount = children;
                          // Increment children count if it's less than 9
                          const newChildrenCount = childrenCount < 9 ? childrenCount + 1 : childrenCount;

                          setChildren(newChildrenCount); // Update Zustand state
                          updateRoomsAndAdultsCount(newChildrenCount, 'children', 'plus');
                          setIsPassengerChanged(true); // Additional logic
                        }}
                        style={{
                          // color: maxGuestsCount === maxRooms ? '#333' : COLOR.primary,
                          // borderColor: maxGuestsCount === maxRooms ? 'transparent' : COLOR.primary,
                          color: COLOR.primary,
                          borderColor: COLOR.primary,
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>

                  <div className="hotel-guest-room-info">
                    <div>
                      <CircleGallySvg _width={64} _height={64} />
                    </div>
                    <div>
                      <p className="satoshi-medium">For more than 8 rooms, we advise you to contact our support team to avail better deals for group bookings.</p>
                      <a className="generalsans-medium" href="#/" onClick={(e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault()}>
                        Contact GalaGO! Team{' '}
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row d-flex justify-content-end">
            <div className="col-lg-3 mb-2">
              <GalagoButton
                icon={
                  <span className="me-2 mb-1">
                    <SearchSvg _color="#fff" />
                  </span>
                }
                label={'SEARCH'}
                disabled={disableButton}
                className={`w-100 search-hotel-button ${disableButton ? 'btn-secondary button-disabled' : 'btn-primary'}`}
                onClick={() => {
                  handleButtonClick();
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {alertModal ? (
        <AlertBox size={'lg'} show={alertModal} onHide={() => setAlertModal(false)}>
          <div className="location-alert-container">
            <div className="location-alert ">
              <div className="location-alert-image">
                <img src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/--GALAGO+WEB+ASSETS/icons-and-backgrounds/alert_svg.png" alt="alert-background" />
              </div>
              <div className="location-alert-text flex flex-column gap-2">
                <p className="text-heading">GalaGO! requires your location to ensure you get the best travel experiences!</p>
                <p className="text-alert">
                  Allowing location access lets GalaGO! Discover hidden gems and exclusive deals nearby, streamlining your travel journey. No worries if you prefer not to share your location, GalaGO!
                  can still guide you as you explore by searching addresses or landmarks.
                </p>
              </div>
              <div className="location-alert-button">
                <GalagoButton
                  label="Okay"
                  btn="primary"
                  size="lg"
                  className="w-25"
                  onClick={() => {
                    setAlertModal(false);
                    window.location.reload();
                  }}
                />
              </div>
            </div>
          </div>
        </AlertBox>
      ) : null}
    </>
  );
};
