import { FlightFilterTypeStore } from '../../../store/FlightFilterTypeStore';
import { FlightFilterStoreRoundTrip } from '../../../store/FlightFiltersStoreRoundTrip';
import { FlightFiltersStoreOneWay } from '../../../store/FlightFiltersStoreOneWay';
import { searchOneWayFlightsApiRoute, searchRoundTripBundledFlightsApiRoute } from '../../../utils/apiRoutes';

export const FlightUrlHook = () => {
  const { flightFilterType } = FlightFilterTypeStore();
  const { selectedPriceRange, selectedDepartureTimeRange, selectedArrivalTimeRange, selectedStops, selectedCabinAndCheckInBaggage, selectedAirlines } = FlightFiltersStoreOneWay();
  const { selectedReturnDepartureTimeRange, selectedReturnArrivalTimeRange, selectedReturnStops, selectedReturnCabinAndCheckInBaggage, selectedReturnAirlines } = FlightFilterStoreRoundTrip();

  /**
   *
   * @param flightType one-way, round-trip, multi-city
   * @param searchParams url parameters for flight search
   * @returns object with URL and urlPayload eg. {URL: https://api.com?param1=1&param2=2, urlPayload: param1=1&param2=2}
   */
  const flightSearchUrl = (flightType: string | undefined, searchParams: URLSearchParams) => {
    const getOriginCode = searchParams.get('originCode');
    const getDestinationCode = searchParams.get('destinationCode');
    const getDepartureDate = searchParams.get('departureDate');
    const getReturnDate = searchParams.get('returnDate');
    const getAdults = searchParams.get('adults') ?? 1;
    const getMinors = searchParams.get('minors') ?? 0;
    const getChildren = searchParams.get('children') ?? 0;
    const getInfants = searchParams.get('infants') ?? 0;
    const getCabinClass = searchParams.get('class');
    const filterKey = searchParams.get('filterKey');
    const getPriceRange = searchParams.get('priceRange');

    // settings for sorting flights
    let getSortedBy = '';
    if (flightFilterType === 'departure') {
      getSortedBy = '&sortedBy=' + (searchParams.get('sortedBy') ?? 'LowestPrice');
    }
    if (flightFilterType === 'return') {
      getSortedBy = '&returnSortedBy=' + (searchParams.get('returnSortedBy') ?? 'LowestPrice');
    }

    let filterUrlParams = '';
    // console.log(searchParams.get('priceRange'));
    if (filterKey) {
      filterUrlParams += `&filterKey=${filterKey}`;

      if (getPriceRange) {
        filterUrlParams += `&priceRange=${searchParams.get('priceRange')}`;
      } 
    
      // departure flight filters
      if (flightFilterType === 'departure') {
        if (selectedDepartureTimeRange.start !== '' && selectedDepartureTimeRange.end !== '') {
          filterUrlParams += `&departureTimeRange=${selectedDepartureTimeRange.start}-${selectedDepartureTimeRange.end}`;
        }
        if (selectedArrivalTimeRange.start !== '' && selectedArrivalTimeRange.end !== '') {
          filterUrlParams += `&arrivalTimeRange=${selectedArrivalTimeRange.start}-${selectedArrivalTimeRange.end}`;
        }
        if (selectedStops.length !== 0) {
          filterUrlParams += `&stops=${searchParams.get('stops')}`;
        }
        if (selectedCabinAndCheckInBaggage) {
          filterUrlParams += `&withCheckInBaggage=${searchParams.get('withCheckInBaggage')}`;
        }
        if (selectedAirlines.length !== 0) {
          filterUrlParams += `&airlines=${searchParams.get('airlines')}`;
        }
      }
      // return flight filters
      if (flightFilterType === 'return') {
        if (selectedReturnDepartureTimeRange.start !== '' && selectedReturnDepartureTimeRange.end !== '') {
          filterUrlParams += `&returnDepartureTimeRange=${selectedReturnDepartureTimeRange.start}-${selectedReturnDepartureTimeRange.end}`;
        }
        if (selectedReturnArrivalTimeRange.start !== '' && selectedReturnArrivalTimeRange.end !== '') {
          filterUrlParams += `&returnArrivalTimeRange=${selectedReturnArrivalTimeRange.start}-${selectedReturnArrivalTimeRange.end}`;
        }
        if (selectedReturnStops.length !== 0) {
          filterUrlParams += `&returnStops=${searchParams.get('returnStops')}`;
        }
        if (selectedReturnCabinAndCheckInBaggage) {
          filterUrlParams += `&returnWithCheckInBaggage=${searchParams.get('returnWithCheckInBaggage')}`;
        }
        if (selectedReturnAirlines.length !== 0) {
          filterUrlParams += `&returnAirlines=${searchParams.get('returnAirlines')}`;
        }
      }
    }

    // one-way and round-trip url switching logic
    const searchType: 'one-way' | 'round-trip' | 'multi-city' = flightType as 'one-way' | 'round-trip' | 'multi-city';
    let url = searchOneWayFlightsApiRoute();
    if (searchType === 'round-trip') {
      // url = searchRoundTripFlightsApiRoute();
      url = searchRoundTripBundledFlightsApiRoute();
    }

    // combine adults and minors to get total adults
    const totalAdults: number = Number(getAdults) + Number(getMinors);
    const urlPayload: string = `departureDate=${getDepartureDate}&returnDate=${getReturnDate}&originCode=${getOriginCode}&destinationCode=${getDestinationCode}&adults=${totalAdults}&children=${getChildren}&infants=${getInfants}&preferredClass=${getCabinClass}${getSortedBy}${filterUrlParams}`;

    return {
      URL: url + '?' + urlPayload,
      urlPayload: urlPayload,
    };
  };

  return { flightSearchUrl };
};
