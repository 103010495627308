import '../../../resources/css/hotel-card.scss';
import '../../../resources/css/tooltip.scss';
import React, { CSSProperties, ReactElement, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { GalagoButton } from '../../../components/Button';
import { formatPriceNoRound } from '../../../utils/data.utils';
import { InfoCircle } from '../../../resources/svg/InfoSvg';
// import { MissingPhotoSvg } from '../../../resources/svg/MissingPhotoSvg';

type HotelCardType = {
  hotel: { [index: string]: any };
  hotelRating: number;
  inclusions?: string;
  imageAltText?: string | undefined;
  bookingDetails?: string;
  bookingDetails2?: string;
  propertyOrDescription?: string;
  minRoomPrice: number;
  nights: number;
  cheapestRoom: { [index: string]: any };
  starRatingCount: number;
  cityName?: string;
  distance?: string;
  /** Style props */
  frameDivBorderRadius?: CSSProperties['borderRadius'];
  frameDivBorder?: CSSProperties['border'];
  frameDivBoxShadow?: CSSProperties['boxShadow'];
  searchCriteria?: { [index: string]: any };
};

const HotelCard = ({
  hotelRating,
  inclusions,
  imageAltText,
  bookingDetails,
  cityName,
  distance,
  bookingDetails2,
  frameDivBorderRadius,
  frameDivBorder,
  frameDivBoxShadow,
  propertyOrDescription,
  minRoomPrice,
  cheapestRoom,
  nights,
  hotel,
  searchCriteria,
  starRatingCount,
}: HotelCardType) => {
  const frameDiv11Style: CSSProperties = useMemo(() => {
    return {
      borderRadius: frameDivBorderRadius,
      border: frameDivBorder,
      boxShadow: frameDivBoxShadow,
    };
  }, [frameDivBorderRadius, frameDivBorder, frameDivBoxShadow]);
  const navigate = useNavigate();
  const [displayed, setDisplayed] = useState(false);
  const minRoomPriceToFloat = minRoomPrice / nights;
  const minRoomPriceFormatted = formatPriceNoRound(minRoomPriceToFloat);

  // let bookingCode = hotel.Rooms[0].BookingCode
  let roomCodes = '';
  // eslint-disable-next-line
  hotel.Rooms.map((value: { BookingCode: string }, key: React.Key) => {
    roomCodes += value.BookingCode + ',';
  });
  // console.log(roomCodes);

  const starRating: ReactElement[] = [];
  for (let index = 0; index < hotelRating; index++) {
    starRating.push(
      <span className="text-primary" key={index}>
        ★
      </span>
    );
    starRatingCount = index + 1;
  }

  // console.log(hotel.Rooms);

  const hotelImages: string[] = hotel.Images;
  const hotelImage = hotelImages === undefined ? null : hotelImages[0];

  let totalRoomPrice: number = 0;
  // eslint-disable-next-line
  cheapestRoom.DayRates[0].map((value: { BasePrice: number }, key: React.Key) => {
    totalRoomPrice += value.BasePrice;
  });

  return (
    <div
      className="rounded-xl margin-bottom bg-white-white-100 box-border w-100 flex flex-row p-6 items-center justify-start gap-[5.19rem] text-left text-[1.25rem] text-black font-footnote border-[1px] border-solid border-border-colors-border-primary "
      style={frameDiv11Style}
    >
      <div className="w-[80%] flex flex-row items-center justify-start gap-[1rem] border-end">
        <div>
          {/* {hotelImage !== null && hotelImage !== undefined ? ( */}
          <img
            className="rounded-3xs w-[15.38rem] h-[13.25rem] object-cover"
            loading="lazy"
            alt={propertyOrDescription}
            src={hotelImage !== null && hotelImage !== undefined ? hotelImage : '/img/missing-img.png'}
            onError={(e) => {
              e.currentTarget.src = '/img/missing-img.png';
            }}
            style={{ minWidth: '15.38rem', minHeight: '13.25rem' }}
          />
          {/* // ) : (
          //   <MissingPhotoSvg />
          // )} */}
        </div>

        <div className="flex flex-col items-start justify-start gap-3.5 w-[70%] me-[1rem] pl-4">
          <div className="flex flex-col items-start justify-start gap-1.5 w-full ">
            <div className="flex flex-col items-start gap-2 ">
              <p style={{ fontSize: 20, fontWeight: 600, fontFamily: 'General Sans' }}>{propertyOrDescription}</p>
              <div style={{ fontSize: 16, fontWeight: 500, fontFamily: 'General Sans', gap: 6, display: 'flex', flexDirection: 'column' }}>
                <p>{bookingDetails}</p>

                <p>
                  {cityName} <span style={{ color: '#A2A2A2' }}>{distance}</span>
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-row items-start justify-start text-sm text-color-teal-teal-900">
            <div className="rounded-lg bg-lightcyan flex flex-row px-2.5 py-1 items-start justify-start ">
              <div className="text-sm font-medium">{inclusions?.toLowerCase().replaceAll(',', ', ')}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-[20%] flex flex-col items-end justify-start gap-[1rem] text-[1rem]">
        <div className="leading-[1.31rem] font-medium">
          {starRating}
          <span className=" generalsans-regular text-sm ms-1">{` ${starRatingCount} - star hotel`}</span>
          {/* (22 reviews) */}
        </div>

        <div className="flex flex-col items-end justify-end gap-0.5">
          <small className="fw-medium textColor text-sm">
            Total Price <span className="text-[#adadad]">( tax Included )</span>
          </small>
          <div className="flex flex-row items-center justify-center gap-2 text-[1.5rem] text-gray-900">
            <div className="font-semibold flex gap-1.5 items-center shrink-0 textColor text-2xl">
              <span>PHP</span>
              <span>{` ${minRoomPriceFormatted} `}</span>
            </div>

            {/* <div className="rounded-xl bg-color-error-red-700 w-[5.31rem] flex flex-row py-0.5 px-1 box-border items-center justify-center text-[1.06rem] text-white-white-100">
              <div className="relative leading-[1.38rem] font-medium">{discount * 100}% OFF</div>
            </div> */}
            {/* Tooltip */}
            <div className="cursor-pointer" id="parentTooltip" onMouseEnter={() => setDisplayed(true)} onMouseLeave={() => setDisplayed(false)}>
              <i className="flex h-6 w-6">
                <InfoCircle _width={24} _height={24} />
              </i>
              {displayed && (
                <div className="position-absolute displayedTooltip z-10" id="childTooltip">
                  <Container className="info bg-light  shadow-2xl rounded-xl" style={{ border: '2px solid #C8E2E8', overflow: 'hidden' }}>
                    <Row>
                      <Col className="d-flex justify-content-between align-center" style={{ padding: '20px 16px' }}>
                        <div className="flex flex-column textColor gap-0">
                          <span className="text-lg generalsans-medium p-0">Total Room Price</span>
                          <span className="text-sm generalsans-medium p-0">
                            ({` for ${nights} `} {nights === 1 ? 'night ' : 'nights '})
                          </span>
                          {/* <span>PHP</span> {minRoomPriceFormatted} x {nights} {nights === 1 ? 'night' : 'nights'} */}
                        </div>

                        <div className="flex flex-row textColor gap-1 generalsans-medium text-lg align-middle">
                          <span className="p-0">PHP</span>
                          <span className="p-0">{formatPriceNoRound(totalRoomPrice)}</span>
                        </div>
                      </Col>
                    </Row>

                    {cheapestRoom.DayRates[0].map((value: { BasePrice: number }, key: React.Key) => {
                      const nightNumber = Number(key) + 1;
                      return (
                        <Row key={key}>
                          <Col className="hotel-card-price">
                            <p >Night {nightNumber}</p>

                            <div className=" flex">
                              <div className="flex">
                                <p> PHP</p>
                              </div>
                              <p>{formatPriceNoRound(value.BasePrice)}</p>
                            </div>
                          </Col>
                        </Row>
                      );
                    })}

                    <Row className="mt-2.5 mb-[20px]">
                      <Col className="d-flex justify-content-between text-muted">
                        <p className="text-secondary text-base">Taxes and charges</p>
                        <p className="text-secondary text-base ">
                          <span>PHP</span> {formatPriceNoRound(cheapestRoom.TotalTax)}
                        </p>
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col className="d-flex justify-content-between">
                        <p>Discount</p>
                        <p className="positive">
                          - <span>PHP</span> {discountPriceFormatted}
                        </p>
                      </Col>
                    </Row> */}
                    {/* <Row>
                      <Col className="px-2.8">
                        <p className="DiscountText" style={{ fontSize: '15px' }}>
                          You’re getting a discount because you’re an early bird
                        </p>
                      </Col>
                    </Row> */}
                    {/* <hr /> */}
                    <Row style={{ padding: '16px 0', backgroundColor: '#E9F4F8' }}>
                      <Col className="d-flex justify-content-between">
                        <p className="text-lg generalsans-medium">Total</p>
                        <p className="text-lg generalsans-medium">
                          <span>PHP</span> {formatPriceNoRound(cheapestRoom.TotalFare)}
                        </p>
                      </Col>
                    </Row>
                  </Container>
                </div>
              )}
            </div>
          </div>
          {/* <small className="text-secondary text-sm">Tax included</small> */}
        </div>

        <GalagoButton
          btn="primary"
          onClick={() => {
            navigate(`/hotel/profile?roomCodes=${roomCodes}`, {
              state: {
                payload: {
                  hotel,
                  nights,
                  bookingDetails: searchCriteria,
                  searchCriteria: searchCriteria,
                },
              },
            });
          }}
          label="See Availability"
          style={{ width: '100%', padding: '10px 20px', fontWeight: '400', fontFamily: 'Satoshi' }}
        />
      </div>
    </div>
  );
};

export default HotelCard;
