import React, { MouseEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { SearchPageHook } from '../../hooks/SearchPageHook';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { SwitchArrowSvg } from '../../resources/svg/SwitchArrowSvg';
import { FloatingIconFormControl } from '../../components/FormControl';
import { CalendarSvg } from '../../resources/svg/CalendarSvg';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../../resources/css/async-type-ahead.scss';
import '../../resources/css/flight-tab-index.scss';
import { GalagoButton } from '../../components/Button';
import { SearchSvg } from '../../resources/svg/SearchSvg';
import { LocationSvg } from '../../resources/svg/LocationSvg';
import { CloseCircleFilledSvg } from '../../resources/svg/CloseSvg';
import { FlightInfoStore } from '../../store/FlightInfoStore';
import { PassengerSvg } from '../../resources/svg/PassengerSvg';
import { CabinSvg } from '../../resources/svg/CabinSvg';
import { TiLocation } from 'react-icons/ti';
import { AirportSvg } from '../../resources/svg/AirportSvg';
import { ChevronDownV2 } from '../../resources/svg/ChevronDownV2';
import { Passengers } from '../../components/Passengers';
import { PassengersStore } from '../../store/PassengersStore';
import { AsyncTypestore } from '../../store/AsyncTypestore';
import { FlightDates } from '../../store/FlightDatesStore';
import { FlightCity } from '../../store/FlightCityStore';

export const FlightTabIndex = () => {
  const { setFlightInfo, setShowFlightInfoModal, setShowReturnFlights, setShowReturnFlightsInfo } = FlightInfoStore();
  const PassengerStore = PassengersStore();
  const passengerTypes = PassengerStore.passengers;
  const { airportLocations } = SearchPageHook();
  const navigate = useNavigate();
  const fromRef = useRef<any>(null);
  const toRef = useRef<any>(null);
  const [showPassengerForm, setShowPassengerForm] = useState(false);
  const [showDepartingCalendar, setShowDepartingCalendar] = useState(false);
  const [showReturnCalendar, setShowReturnCalendar] = useState(false);
  const [activeFlightSwitch, setActiveFlightSwitch] = useState('round-trip');
  const [showCabinClassForm, setShowCabinClassForm] = useState(false);
  const currentDate = moment(new Date()).format('MMM DD, YYYY');
  //eslint-disable-next-line
  const [defaultDepartureDate, setDefaultDepartureDate] = useState(currentDate);
  const [defaultReturnDate, setDefaultReturnDate] = useState('');

  const [isDepartureDateActive, setIsDepartureDateActive] = useState(false);
  const [isReturnDateActive, setIsReturnDateActive] = useState(false);
  const [isPassengerActive, setIsPassengerActive] = useState(false);
  //eslint-disable-next-line
  const [isPassengerChanged, setIsPassengerChanged] = useState(false);
  const [isCabinClassActive, setIsCabinClassActive] = useState(false);
  const [isCabinClassChanged, setIsCabinClassChanged] = useState(false);
  const [cabinLabel, setCabinLabel] = useState('Economy');

  // form defaultValues

  const { flyingFrom, setFlyingFrom, flyingTo, setFlyingTo, setFromDetails, setToDetails, fromDetails, toDetails, setIsFromActive, setIsToActive, isFromActive, isToActive } = AsyncTypestore();
  const { departingDate, setDepartingDate, returningDate, setReturningDate } = FlightDates();
  const { setSelectedToCityName, setSelectedFromCityName } = FlightCity();

  // const [departingDate, setDeparting] = useState('');
  // const [returnDate, setReturnDate] = useState('');
  //eslint-disable-next-line
  const [adults, setAdults] = useState(1);
  const [cabin, setCabin] = useState('economy');

  const handleClosePopups = (param: boolean) => {
    setShowDepartingCalendar(param);
    setShowReturnCalendar(param);
    setShowPassengerForm(param);
    setShowCabinClassForm(param);
  };

  // eslint-disable-next-line
  const [loadingAirportList, setLoadingAirportList] = useState(false);
  const [FromAirportList, setFromAirportList] = useState<{ [index: string]: any }>();
  const [FromMappedFlights, setFromMappedFlights] = useState<any>();

  useEffect(() => {
    if (FromAirportList && Array.isArray(FromAirportList.tag)) {
      const _country: string[] = [];
      const foundAirports = FromAirportList.tag;

      if (Array.isArray(foundAirports)) {
        //* Collect unique country locations
        foundAirports.forEach((airport: { location: string }) => {
          if (airport && airport.location && !_country.includes(airport.location)) {
            _country.push(airport.location);
          }
        });

        const _FromMappedAirports: { [index: string]: any } = [];
        let _index = 0;

        //* Map country locations to airport details
        _country.forEach((_airport) => {
          foundAirports.forEach((airport: { location: string; airportCode: string; airportName: string; cityName: string; countryCode: string; airports: any[] }) => {
            if (_airport === airport.location) {
              //* Include main airport details (airportCode and airportName)
              _FromMappedAirports[_index] = {
                index: _index,
                name: airport.location,
                code: airport.airportCode,
                isCountry: true,
                details: null,
              };
              _index++;

              airport.airports.forEach((detail: any) => {
                _FromMappedAirports[_index] = {
                  index: _index,
                  name: airport.cityName,
                  code: airport.countryCode,
                  isCountry: false,
                  details: detail,
                };
                _index++;
              });
            }
          });
        });

        //* Set the mapped flights to state
        setFromMappedFlights(_FromMappedAirports);
      } else {
        console.error('FromAirportList.tag is not an array:', foundAirports);
      }
    }
  }, [FromAirportList]);

  // eslint-disable-next-line
  const [ToAirportList, setToAirportList] = useState<{ [index: string]: any }>();
  const [ToMappedFlights, setToMappedFlights] = useState<any>();

  useEffect(() => {
    if (ToAirportList && Array.isArray(ToAirportList.tag)) {
      const _country: string[] = [];
      const foundAirports = ToAirportList.tag;

      if (Array.isArray(foundAirports)) {
        foundAirports.forEach((airport: { location: string }) => {
          if (airport && airport.location && !_country.includes(airport.location)) {
            _country.push(airport.location);
          }
        });

        const _ToMappedAirports: { [index: string]: any } = [];
        let _index = 0;

        _country.forEach((_airport) => {
          foundAirports.forEach((airport: { location: string; airportCode: string; airportName: string; cityName: string; countryCode: string; airports: any[] }) => {
            if (_airport === airport.location) {
              _ToMappedAirports[_index] = {
                index: _index,
                name: airport.location,
                code: airport.airportCode,
                isCountry: true,
                details: null,
              };
              _index++;

              airport.airports.forEach((detail: any) => {
                _ToMappedAirports[_index] = {
                  index: _index,
                  name: airport.cityName,
                  code: airport.countryCode,
                  isCountry: false,
                  details: detail,
                };
                _index++;
              });
            }
          });
        });

        setToMappedFlights(_ToMappedAirports);
      } else {
        console.error('ToAirportList.tag is not an array:', foundAirports);
      }
    }
  }, [ToAirportList]);

  const switchDestination = () => {
    let from = flyingFrom;
    let to = flyingTo;
    setFlyingFrom(to);
    setFlyingTo(from);

    let fromDets = fromDetails;
    let toDets = toDetails;
    setFromDetails(toDets);
    setToDetails(fromDets);

    let fromRefState = fromRef.current.state.selected;
    let toRefState = toRef.current.state.selected;
    fromRef.current.state.selected = toRefState;
    toRef.current.state.selected = fromRefState;
  };



  const [disabledButtonSearch, setDisabledButtonSearch] = useState(true);
  useEffect(() => {
    setDisabledButtonSearch(true);
    if (activeFlightSwitch === 'one-way') {
      if (flyingFrom && flyingTo && (departingDate || defaultDepartureDate) && adults && cabin) {
        setDisabledButtonSearch(false);
      }
    }
    if (activeFlightSwitch === 'round-trip') {
      if (flyingFrom && flyingTo && (departingDate || defaultDepartureDate) && (returningDate || defaultReturnDate) && adults && cabin) {
        setDisabledButtonSearch(false);
      }
    }

    // For onFocus  state
    fromRef.current.inputNode.style.borderColor = '';
    if (flyingFrom) {
      fromRef.current.inputNode.style.borderColor = '#4FA2B4';
    }
    toRef.current.inputNode.style.borderColor = '';
    if (flyingTo) {
      toRef.current.inputNode.style.borderColor = '#4FA2B4';
    }
    // eslint-disable-next-line
  }, [flyingFrom, flyingTo, departingDate, returningDate, cabin, activeFlightSwitch]);

  const handleLabelChange = (e: any) => {
    if (e.isCountry) {
      return `${e.name}`;
    } else {
      return `${e.details?.airportName}`;
    }
  };

  const handleClick = (e: any) => {
    if (disabledButtonSearch) {
      let alertMessage = '';
      if (!flyingFrom && !flyingTo) {
        alertMessage = 'Please select departure and arrival airport';
      } else if (!flyingFrom) {
        alertMessage = 'Please select departure airport';
      } else if (!flyingTo) {
        alertMessage = 'Please select arrival airport';
      }

      if (alertMessage) {
        alert(alertMessage);
        e.stopPropagation();
      }
    }
  };

  useEffect(() => {
    setDefaultReturnDate(moment(defaultDepartureDate).add(1, 'days').format('MMM DD, YYYY'));
    if (departingDate !== '') {
      setDefaultReturnDate(moment(departingDate).add(1, 'days').format('MMM DD, YYYY'));
    }

    if (returningDate !== '') {
      if (moment(departingDate).isSameOrAfter(moment(returningDate ? returningDate : defaultReturnDate))) {
        setReturningDate(moment(departingDate).add(1, 'days').format('MMM DD, YYYY'));
      }
    }
    // eslint-disable-next-line
  }, [departingDate]);

  return (
    <>
      <div className="tab-pane fade show active" id="flight-tab-pane" role="tabpanel" aria-labelledby="flight-tab">
        <div className="container px-5 py-4">
          <div className="mb-4 row">
            <div className="col-lg-auto">
              <div className="btn-group w-100" id="flights-switch">
              <a
                href="/#"
                onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                  e.preventDefault();
                  setActiveFlightSwitch('one-way');
                }}
                className={activeFlightSwitch === 'one-way' ? `btn btn-primary btn-sm text-white no-link-style` : `btn btn-outline-primary btn-sm`}
                style={{ textDecoration: 'none', color: '#016e7f' }}
              >
                One Way
              </a>
              <a
                href="/#"
                onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                  e.preventDefault();
                  setActiveFlightSwitch('round-trip');
                }}
                className={activeFlightSwitch === 'round-trip' ? `btn btn-primary btn-sm text-white no-link-style` : `btn btn-outline-primary btn-sm`}
                style={{ textDecoration: 'none', color: '#016e7f' }}
              >
                Round-Trip
              </a>
                {/* <a href="#" onClick={() => setActiveFlightSwitch('multi-city')} className={activeFlightSwitch === 'multi-city' ? `btn btn-primary btn-sm` : `btn btn-outline-primary btn-sm`}>
                  Multi-City
                </a> */}
              </div>
            </div>
          </div>
          <div className="mb-4">
            <div className="d-flex flying gap-3 justify-between align-center" id="airport-location-holder">
              <div className="flex-fill">
                <label htmlFor="" className="form-label">
                  Departure
                </label>
                <span className="position-relative d-block">
                  <LocationSvg _color={flyingFrom || isFromActive ? '#4FA2B4' : '#A8A8A8'} className="location-svg position-absolute" style={{ zIndex: 1, left: '12px', top: '18px' }} />
                  {flyingFrom !== '' ? (
                    <CloseCircleFilledSvg
                      _color={'#4FA2B4'}
                      className="clear-text-svg"
                      onClick={() => {
                        setFlyingFrom('');
                        setFromDetails('');
                        // setAirportOptions([{}]);
                        fromRef.current.state.text = '';
                        fromRef.current.state.selected = [];
                        fromRef.current.inputNode.value = '';
                      }}
                    />
                  ) : null}
                  <AsyncTypeahead
                    ref={fromRef}
                    id="async-flying-from"
                    className="async-flight-locations"
                    isLoading={loadingAirportList}
                    delay={500}
                    onSearch={function (query: string): void {
                      airportLocations(query).then((response: { [index: string]: any }) => {
                        setFromAirportList(response);
                      });
                    }}
                    onChange={(e: { [index: string]: any }) => {
                      const airportCode = e[0]?.details?.airportCode ?? e[0]?.code;
                      const airportName = e[0]?.details?.airportName ?? e[0]?.name;
                      const cityName = e[0]?.details?.cityName ?? e[0]?.city;
                      setFlyingFrom(airportCode);
                      setFromDetails(airportName);
                      setSelectedFromCityName(cityName);
                      // setAirportOptions([{}]);
                    }}
                    onKeyDown={(e) => {
                      if (!/[a-zA-Z, ]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onFocus={() => {
                      handleClosePopups(false);
                      setIsFromActive(true);
                    }}
                    onBlur={() => setIsFromActive(false)}
                    labelKey={(e) => handleLabelChange(e)}
                    placeholder="Country, City or Airport"
                    options={FromMappedFlights}
                    minLength={1}
                    filterBy={() => true}
                    renderMenuItemChildren={(e: any) => {
                      return (
                        <div
                          className={
                            !e?.isCountry
                              ? 'ps-4 d-flex flex-wrap align-items-start justify-between generalsans-medium w-100 max-w-100'
                              : 'd-flex generalsans-medium w-full user-select-none pointer-events-none '
                          }
                        >
                          <div className="pt-0.4 flex  gap-2 align-items-center justify-between">
                            {e?.isCountry ? <TiLocation /> : <AirportSvg />}
                            <span className="">{e.details?.airportName} </span>
                          </div>
                          {e?.isCountry && (
                            <div className="d-flex flex-column  justify-between w-full">
                              <div className="flex justify-between align-items-center">
                                <div className="flex">{e.name} </div>
                                <span className="">{e.isCountry && 'All Airports'}</span>
                              </div>
                            </div>
                          )}

                          <span>{e.details?.airportCode}</span>
                        </div>
                      );
                    }}
                  />
                </span>
              </div>
              <div className=" svg-arrow">
                <div className="switch-arrow-svg mb-1 switch" onClick={() => switchDestination()}>
                  <SwitchArrowSvg _color="#fff" />
                </div>
              </div>
              <div className="flex-fill">
                <label htmlFor="" className="form-label">
                  Arrival
                </label>
                <span className="position-relative d-block">
                  <LocationSvg _color={flyingTo || isToActive ? '#4FA2B4' : '#A8A8A8'} className="location-svg position-absolute" style={{ zIndex: 1, left: '12px', top: '18px' }} />
                  {flyingTo !== '' ? (
                    <CloseCircleFilledSvg
                      _color={'#4FA2B4'}
                      className="clear-text-svg"
                      onClick={() => {
                        setFlyingTo('');
                        setToDetails('');
                        // setAirportOptions([{}]);
                        toRef.current.state.text = '';
                        toRef.current.state.selected = [];
                        toRef.current.inputNode.value = '';
                      }}
                    />
                  ) : null}
                  <AsyncTypeahead
                    ref={toRef}
                    id="async-flying-to"
                    className="async-flight-locations"
                    isLoading={loadingAirportList}
                    delay={500}
                    onSearch={function (query: string): void {
                      airportLocations(query).then((response: { [index: string]: any }) => {
                        setToAirportList(response);
                      });
                    }}
                    onChange={(e: { [index: string]: any }) => {
                      const airportCode = e[0]?.details?.airportCode ?? e[0]?.code;
                      const airportName = e[0]?.details?.airportName ?? e[0]?.name;
                      const cityName = e[0]?.details?.cityName ?? e[0]?.city;
                      setFlyingTo(airportCode);
                      setToDetails(airportName);
                      setSelectedToCityName(cityName);
                      // setAirportOptions([{}]);
                    }}
                    onKeyDown={(e) => {
                      if (!/[a-zA-Z ]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    onFocus={() => {
                      handleClosePopups(false);
                      setIsToActive(true);
                    }}
                    onBlur={() => setIsToActive(false)}
                    labelKey={(e) => handleLabelChange(e)}
                    placeholder="Country, City or Airport"
                    options={ToMappedFlights}
                    minLength={1}
                    filterBy={() => true}
                    renderMenuItemChildren={(e: any) => (
                      <div
                        className={
                          !e?.isCountry
                            ? 'ps-4 d-flex flex-wrap align-items-start justify-between generalsans-medium w-100 max-w-100'
                            : 'd-flex generalsans-medium w-full user-select-none pointer-events-none '
                        }
                      >
                        <div className="pt-0.4 flex  gap-2 align-items-center justify-between">
                          {e?.isCountry ? <TiLocation /> : <AirportSvg />}
                          <span className="">{e.details?.airportName} </span>
                        </div>
                        {e?.isCountry && (
                          <div className="d-flex flex-column  justify-between w-full">
                            <div className="flex justify-between align-items-center">
                              <div className="flex">{e.name} </div>
                              <span className="">{e.isCountry && 'All Airports'}</span>
                            </div>
                          </div>
                        )}

                        <span>{e.details?.airportCode}</span>
                      </div>
                    )}
                  />
                </span>
              </div>
            </div>
          </div>

          {/* Line 2 */}
          <div className="d-flex gap-3  departure-column">
            <div className="flex-fill w-full">
              <FloatingIconFormControl
                onClick={(e: any) => {
                  setShowDepartingCalendar(true);
                  setShowReturnCalendar(false);
                  setShowPassengerForm(false);
                  setShowCabinClassForm(false);
                }}
                value={!departingDate ? defaultDepartureDate : departingDate}
                onFocus={() => setIsDepartureDateActive(true)}
                onBlur={() => setIsDepartureDateActive(false)}
                readOnly={true}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  e.preventDefault();
                  return false;
                }}
                type={'text'}
                label={'Departure Date'}
                icon={<CalendarSvg _color={(flyingFrom && flyingTo) || departingDate || isDepartureDateActive ? '#4FA2B4' : '#A8A8A8'} />}
                // placeholder="Date of Departure"
                placeholder={departingDate}
                autoComplete="off"
                style={{
                  border: (flyingFrom && flyingTo) || departingDate || isDepartureDateActive ? '2px solid #4FA2B4' : '',
                  marginBottom: '10px',
                  cursor: 'pointer',
                }}
              />
              {showDepartingCalendar && (
                <div className="flight-calendar-holder " onMouseLeave={() => setShowDepartingCalendar(false)}>
                  <Calendar
                    onChange={(e: any) => {
                      setShowDepartingCalendar(false);
                      setDepartingDate(moment(e).format('MMM DD, YYYY'));
                      setShowReturnCalendar(true);
                      setIsReturnDateActive(true);
                      // setMaxDate(moment(e).add(1, 'years').format('YYYY-MM-DD'));
                    }}
                    defaultValue={departingDate ? new Date(departingDate) : new Date(defaultDepartureDate)}
                    minDate={new Date()}
                    maxDate={new Date(moment().add(1, 'year').format('YYYY-MM-DD'))}
                  />
                </div>
              )}
            </div>
            {activeFlightSwitch === 'round-trip' ? (
              <div className="flex-flex-fill w-full">
                <FloatingIconFormControl
                  value={returningDate}
                  onClick={(e: any) => {
                    setShowReturnCalendar(true);
                    setShowDepartingCalendar(false);
                    setShowPassengerForm(false);
                    setShowCabinClassForm(false);
                  }}
                  onFocus={() => {
                    setIsReturnDateActive(true);
                  }}
                  onBlur={() => setIsReturnDateActive(false)}
                  readOnly={true}
                  onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    e.preventDefault();
                    return false;
                  }}
                  type={'text'}
                  label={'Return Date'}
                  icon={<CalendarSvg _color={(flyingFrom && flyingTo) || returningDate || isReturnDateActive ? '#4FA2B4' : '#A8A8A8'} />}
                  placeholder={returningDate === '' ? defaultReturnDate : returningDate}
                  autoComplete="off"
                  id="return-date-frm"
                  style={{
                    border: (flyingFrom && flyingTo) || returningDate || isReturnDateActive ? '2px solid #4FA2B4' : '',
                    cursor: departingDate ? 'pointer' : 'not-allowed',
                  }}
                />
                {showReturnCalendar && departingDate !== '' ? (
                  <div className="flight-calendar-holder" onMouseLeave={() => setShowReturnCalendar(false)}>
                    <Calendar
                      onChange={(e: any) => {
                        setReturningDate(moment(e).format('MMM DD, YYYY'));
                        setShowDepartingCalendar(false);
                        setShowReturnCalendar(false);
                      }}
                      value={returningDate ? new Date(returningDate) : new Date(defaultReturnDate)}
                      minDate={departingDate ? new Date(departingDate) : new Date()}
                      maxDate={new Date(moment(departingDate).add(1, 'years').format('YYYY-MM-DD'))}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            <div className="w-full">
              <div className="position-relative">
                <FloatingIconFormControl
                  onClick={() => {
                    setShowPassengerForm(true);
                    setShowDepartingCalendar(false);
                    setShowReturnCalendar(false);
                    setShowCabinClassForm(false);
                  }}
                  onFocus={() => setIsPassengerActive(true)}
                  onBlur={() => setIsPassengerActive(false)}
                  value={`${passengerTypes.adults} ${passengerTypes.adults > 1 ? 'Adults,' : 'Adult,'} ${passengerTypes.minors} ${passengerTypes.minors > 1 ? 'Minors,' : 'Minor,'} ${
                    passengerTypes.children
                  } ${passengerTypes.children > 1 ? 'Children,' : 'Child,'} ${passengerTypes.infants} ${passengerTypes.infants > 1 ? 'Infants' : 'Infant'}`}
                  type={'text'}
                  icon={<PassengerSvg _color={(flyingFrom && flyingTo) || isPassengerActive || isPassengerChanged ? '#4FA2B4' : '#A8A8A8'} />}
                  caret={
                    <ChevronDownV2
                      style={showPassengerForm ? { transform: 'rotate(180deg)', transition: 'transform 0.3s ease, fill 0.3s ease' } : { transition: 'transform 0.3s ease, fill 0.3s ease' }}
                      _color={(flyingFrom && flyingTo) || isPassengerActive || isPassengerChanged ? '#4fa2b4' : '#adadad'}
                    />
                  }
                  label={'Passengers'}
                  readOnly={true}
                  // placeholder="Passengers"
                  autoComplete="false"
                  style={{
                    border: (flyingFrom && flyingTo) || isPassengerActive || isPassengerChanged ? '2px solid #4FA2B4' : '',
                    cursor: 'pointer',
                  }}
                />
                {showPassengerForm && <Passengers onMouseLeave={() => setShowPassengerForm(false)} />}
              </div>
            </div>
            <div className="w-full">
              <div className="position-relative">
                <FloatingIconFormControl
                  icon={<CabinSvg _color={(flyingFrom && flyingTo) || isCabinClassActive || isCabinClassChanged ? '#4fa2b4' : '#a8a8a8'} />}
                  caret={
                    <ChevronDownV2
                      style={showCabinClassForm ? { transform: 'rotate(180deg)', transition: 'transform 0.3s ease, fill 0.3s ease' } : { transition: 'transform 0.3s ease, fill 0.3s ease' }}
                      _color={(flyingFrom && flyingTo) || isCabinClassActive || isCabinClassChanged ? '#4fa2b4' : '#adadad'}
                    />
                  }
                  id="cabin-class"
                  name="cabin-class"
                  type="select"
                  value={cabinLabel}
                  defaultValue={'Economy'}
                  onClick={() => {
                    setShowDepartingCalendar(false);
                    setShowReturnCalendar(false);
                    setShowPassengerForm(false);
                    setShowCabinClassForm(true);
                  }}
                  label={'Cabin Class'}
                  autoComplete="false"
                  readOnly={true}
                  onBlur={() => (!isCabinClassChanged ? setIsCabinClassActive(false) : '')}
                  onFocus={() => setIsCabinClassActive(true)}
                  style={{
                    border: (flyingFrom && flyingTo) || isCabinClassActive || isCabinClassChanged ? '2px solid #4fa2b4' : '',
                    cursor: 'pointer',
                  }}
                />

                {showCabinClassForm && (
                  <div
                    onMouseLeave={() => setShowCabinClassForm(false)}
                    id="cabin-details-holder"
                    className="position-absolute bg-body d-flex flex-column align-items-start justify-content-center"
                    style={{
                      left: 0,
                      right: 0,
                      // marginLeft: 'auto',
                      marginRight: 'auto',
                      marginTop: '10px',
                      width: '100%',
                    }}
                  >
                    <div
                      className="cabin-opt"
                      onClick={() => {
                        setCabin('economy');
                        setCabinLabel('Economy');
                        setShowCabinClassForm(false);
                        setIsCabinClassChanged(true);
                      }}
                    >
                      Economy
                    </div>
                    <div
                      className="cabin-opt"
                      onClick={() => {
                        setCabin('business');
                        setCabinLabel('Business');
                        setShowCabinClassForm(false);
                        setIsCabinClassChanged(true);
                      }}
                    >
                      Business
                    </div>
                    <div
                      className="cabin-opt"
                      onClick={() => {
                        setCabin('first');
                        setCabinLabel('First Class');
                        setShowCabinClassForm(false);
                        setIsCabinClassChanged(true);
                      }}
                    >
                      First Class
                    </div>
                    <div
                      className="cabin-opt"
                      onClick={() => {
                        setCabin('premiumEconomy');
                        setCabinLabel('Premium Economy');
                        setShowCabinClassForm(false);
                        setIsCabinClassChanged(true);
                      }}
                    >
                      Premium Economy
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="row justify-content-end">
            <div className="col-lg-3" onClick={handleClick}>
              <label className="form-label">&nbsp;</label>
              <GalagoButton
                btn="primary"
                className={`w-100 search-flight-button ${disabledButtonSearch && 'button-disabled'}`}
                disabled={disabledButtonSearch}
                icon={
                  <span className="me-2 mb-1">
                    <SearchSvg _color="#fff" />
                  </span>
                }
                label={'SEARCH'}
                onClick={() => {
                  if (flyingFrom === flyingTo) {
                    alert('Please make sure your departure and arrival are different.');
                    setFlyingFrom('');
                    setFlyingTo('');
                  } else {
                    let finalReturnDate = '';
                    activeFlightSwitch === 'one-way'
                      ? (finalReturnDate = '')
                      : (finalReturnDate = `${returningDate ? moment(returningDate).format('YYYY/MM/DD') : moment(defaultReturnDate).format('YYYY/MM/DD')}`);

                    const urlGetParams = `?originCity=${fromDetails}&destinationCity=${toDetails}&originCode=${flyingFrom}&airportCode=${flyingFrom}&destinationCode=${flyingTo}&departureDate=${
                      departingDate ? moment(departingDate).format('YYYY/MM/DD') : moment(defaultDepartureDate).format('YYYY/MM/DD')
                    }&returnDate=${finalReturnDate}&class=${cabin}&adults=${passengerTypes.adults}&minors=${passengerTypes.minors}&children=${passengerTypes.children}&infants=${
                      passengerTypes.infants
                    }`;
                    let url = `/flights/one-way${urlGetParams}`;
                    if (activeFlightSwitch === 'round-trip') url = `/flights/round-trip${urlGetParams}`;
                    // return;
                    setFlightInfo({});
                    setShowFlightInfoModal(false);
                    setShowReturnFlights(false);
                    setShowReturnFlightsInfo([]);
                    navigate(url);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
