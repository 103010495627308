import React from 'react';
import { Container } from 'react-bootstrap';
import '../resources/css/footer.scss';
import { Link } from 'react-router-dom';
import { ASSETS_API } from '../utils/apiRoutes';

const Footer = () => {
  const resourcesImageApi = ASSETS_API().resources.images;
  return (
    <div className="z-0">
      <Container fluid className="footer">
        <div className="upper-foot align-items-center">
          <div className="d-lg-flex shortcuts">
            <div className="m-0 p-0">
              <h3 className="generalsans-bold">Company</h3>
              <Link to="https://www.walktheplanet.com">
                <p className="generalsans-regular">About Us</p>
              </Link>
              {/* DO NOT DELETE: HIDDEN DUE TO UNAVAILABILITY OF CONTENT */}
              {/* <p>Privacy Policy</p> */}
            </div>
            <div className="m-0 p-0">
              <h3 style={{ color: '#fff' }}>Resources</h3>
              <Link to="/Blogs">
                <p>Blogs</p>
              </Link>
              {/* DO NOT DELETE: HIDDEN DUE TO UNAVAILABILITY OF CONTENT */}
              {/* <p>Promos</p> */}
            </div>
            <div className="m-0 p-0">
              <h3 style={{ color: '#fff' }}>Support</h3>
              <Link to="/help-center">
                <p>Help Center</p>
              </Link>
              <Link to="/privacy-policy">
                <p>Privacy Policy</p>
              </Link>
              <Link to="/terms-and-conditions">
                <p>Terms and Conditions</p>
              </Link>
            </div>
          </div>

          <div className="logo-section">
            <Link to="/">
              <div className="logo-gally w-full mx-auto">
                <img src="https://galago-assets.s3.ap-southeast-1.amazonaws.com/white_galago+1.png" alt="" />
              </div>
            </Link>
            <div className="icons-div">
              <div className="icons">
                <a href="https://www.facebook.com/galagonow">
                  <img src={`${resourcesImageApi}/homepage/facebook-btn.svg`} alt="" />
                </a>
                <a href="https://www.instagram.com/galagonow/">
                  <img src={`${resourcesImageApi}/homepage/instagram-btn.svg`} alt="" />
                </a>
                <a href="https://www.linkedin.com/company/walktheplanet/">
                  <img src={`${resourcesImageApi}/homepage/linkedIn-btn.svg`} alt="" />
                </a>
              </div>
              <div className="foot-note generalsans-regular">
                <p>customersupport@galago.com.ph</p>
              </div>
            </div>
          </div>
        </div>
        <div className="lower-foot satoshi-regular">
          <p>© 2023 WalkThePlanet, Inc. All rights reserved.</p>
        </div>
      </Container>
      <div className="footer-resposive"></div>
    </div>
  );
};

export default Footer;
