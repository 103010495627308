import React from 'react';
import HotelListHistory from './HotelListHistory';
import { GallyNoBookingYet } from '../../resources/svg/GallyNoBookingYet';
import { GalagoButton } from '../../components/Button'
import { useNavigate } from 'react-router-dom';

type HotelHistoryProps = {
  activeTab: 'active' | 'past' | 'cancelled';
  hotelHistory: { [index: string]: any };
};

export const HotelHistory = ({ activeTab, hotelHistory }: HotelHistoryProps) => {
  const { active, past, cancelled } = hotelHistory;
  
  const navigate = useNavigate();

  const toHomePage = () => {
    navigate('/');
  };
  return (
    <>
      {activeTab === 'active' && (
        active && active.length > 0 ? (
          active.map((hotel: { [index: string]: any }, key: React.Key) => (
            <HotelListHistory data={hotel} key={key} />
          ))
        ) : (
          <div className='no-booking-yet'>
            <span>
              <GallyNoBookingYet />
              <h1>No active bookings</h1>
              <p>Pack your bags, grab your passport, and let's explore the wonders of the world together.</p>
            </span>
            <span className='w-25'>
              <GalagoButton label="Book with GalaGO! Now" size='xl' onClick={toHomePage}/>
            </span>
          </div>
        )
      )}

      {activeTab === 'past' && (
        past && past.length > 0 ? (
          past.map((hotel: { [index: string]: any }, key: React.Key) => (
            <HotelListHistory data={hotel} key={key} />
          ))
        ) : (
          <div className='no-booking-yet'>
            <span>
              <GallyNoBookingYet />
              <h1>No past bookings</h1>
              <p>It looks like you haven’t made any bookings yet. Start exploring our services and book your first experience today!</p>
            </span>
            <span className='w-25'>
              <GalagoButton label="Book with GalaGO! Now" size='xl' onClick={toHomePage}/>
            </span>
          </div>
        )
      )}


      {activeTab === 'cancelled' && (
        cancelled && cancelled.length > 0 ? (
          cancelled.map((hotel: { [index: string]: any }, key: React.Key) => (
            <HotelListHistory data={hotel} key={key} />
          ))
        ) : (
          <div className='no-booking-yet'>
            <span>
              <GallyNoBookingYet />
              <h1>No cancelled bookings</h1>
              <p>You don’t have any cancelled bookings at the moment. Feel free to browse and manage your active reservations.</p>
             </span>
            <span className='w-25'>
              <GalagoButton label="Book with GalaGO! Now" size='xl' onClick={toHomePage}/>
            </span>
        </div>
        )
      )}

    </>
  );
};
