import React, { useEffect, useState } from 'react';
import { AirplaneAscendSvg } from '../../../resources/svg/AirplanesSvg';
import { ChevronDownV2 } from '../../../resources/svg/ChevronDownV2';
import { FlightSortingStore } from '../../../store/FlightSortingStore';
import { useNavigate } from 'react-router-dom';
import { FlightSortingOptions } from '../../../utils/flightSortingOptions';
import { FlightFiltersStoreOneWay } from '../../../store/FlightFiltersStoreOneWay';
import { FlightFilterTypeStore } from '../../../store/FlightFilterTypeStore';
// import { url } from 'inspector';

type SelectedFlightLabelType = {
  selectedFlight: { [index: string]: any } | null | undefined;
  fromCityName: string;
  fromAirportCode: string;
  toCityName: string;
  toAirportCode: string;
  resultsCount?: number;
  setSortingFilter?: (value: string) => void;
  searchParams: { [index: string]: any };
};
const SelectedFlightLabel = ({ selectedFlight, fromCityName, fromAirportCode, toCityName, toAirportCode, resultsCount, setSortingFilter, searchParams }: SelectedFlightLabelType) => {
  const { filterKey } = FlightFiltersStoreOneWay();
  const { oneWaySort, setOneWaySort, setSelectedFilter } = FlightSortingStore();
  const { flightFilterType } = FlightFilterTypeStore();
  const [showFilters, setShowFilters] = useState(false);
  const navigate = useNavigate();

  const getOriginCode = searchParams.get('originCode');
  const getDestinationCode = searchParams.get('destinationCode');
  const getOriginCity = searchParams.get('originCity');
  const getDestinationCity = searchParams.get('destinationCity');
  const getDepartureDate = searchParams.get('departureDate');
  const getReturnDate = searchParams.get('returnDate');
  const getAdults = searchParams.get('adults') ?? 1;
  const getMinors = searchParams.get('minors') ?? 0;
  const getChildren = searchParams.get('children') ?? 0;
  const getInfants = searchParams.get('infants') ?? 0;
  const getCabinClass = searchParams.get('class');
  const getFilterKey = searchParams.get('filterKey');
  const getPriceRange = searchParams.get('priceRange');

  //Departure
  const getDepartureTimeRange = searchParams.get('departureTimeRange');
  const getArrivalTimeRange = searchParams.get('arrivalTimeRange');
  const getStops = searchParams.get('stops');
  const getWithCheckInBaggage = searchParams.get('withCheckInBaggage');
  const getAirlines = searchParams.get('airlines');
  //Return
  const getReturnStops = searchParams.get('returnStops');
  const getReturnWithCheckInBaggage = searchParams.get('returnWithCheckInBaggage');
  const getReturnAirlines = searchParams.get('returnAirlines');

  let urlGetParams = '';
  urlGetParams += `?originCode=${getOriginCode}`;
  urlGetParams += `&destinationCode=${getDestinationCode}`;
  urlGetParams += `&originCity=${getOriginCity}`;
  urlGetParams += `&destinationCity=${getDestinationCity}`;
  urlGetParams += `&departureDate=${getDepartureDate}`;
  urlGetParams += `&returnDate=${getReturnDate}`;
  urlGetParams += `&adults=${getAdults}`;
  urlGetParams += `&minors=${getMinors}`;
  urlGetParams += `&children=${getChildren}`;
  urlGetParams += `&infants=${getInfants}`;
  urlGetParams += `&class=${getCabinClass}`;
  // if (getDepartureTimeRange) urlGetParams += `&departureTimeRange=${getDepartureTimeRange}`;
  // if (getArrivalTimeRange) urlGetParams += `&arrivalTimeRange=${getArrivalTimeRange}`;
  // if (getStops) urlGetParams += `&stops=${getStops}`;
  // if (getWithCheckInBaggage) urlGetParams += `&withCheckInBaggage=${getWithCheckInBaggage}`;
  // if (getAirlines) urlGetParams += `&airlines=${getAirlines}`;

  const filterURL = (): string => {
    let filterUrlParams = '';
    if (getFilterKey) filterUrlParams += `&filterKey=${getFilterKey}`;
    else {
      filterUrlParams += `&filterKey=${filterKey}`;
    }
    if (searchParams.get('priceRange')) filterUrlParams += `&priceRange=${getPriceRange}`;
    if (searchParams.get('departureTimeRange')) filterUrlParams += `&departureTimeRange=${getDepartureTimeRange}`;
    if (searchParams.get('arrivalTimeRange')) filterUrlParams += `&arrivalTimeRange=${getArrivalTimeRange}`;
    return filterUrlParams;
  };

  const departureURL = (): string => {
    let departureUrlParams = '';
    if (searchParams.get('stops')) departureUrlParams += `&stops=${getStops}`;
    if (searchParams.get('withCheckInBaggage')) departureUrlParams += `&withCheckInBaggage=${getWithCheckInBaggage}`;
    if (searchParams.get('airlines')) departureUrlParams += `&airlines=${getAirlines}`;
    return departureUrlParams;
  };

  const returnURL = (): string => {
    let returnUrlParams = '';
    if (getReturnStops) returnUrlParams += `&returnStops=${getReturnStops}`;
    if (getReturnWithCheckInBaggage) returnUrlParams += `&returnWithCheckInBaggage=${getReturnWithCheckInBaggage}`;
    if (getReturnAirlines) returnUrlParams += `&returnAirlines=${getReturnAirlines}`;
    return returnUrlParams;
  };

  useEffect(() => {
    if (searchParams.get('sortedBy') !== null) {
      let sortedBy = searchParams.get('sortedBy');
      sortedBy = sortedBy.replace('Price', ' Price');
      sortedBy = sortedBy.replace('Departure', ' Departure');
      sortedBy = sortedBy.replace('Arrival', ' Arrival');
      sortedBy = sortedBy.replace('Duration', ' Duration');
      setOneWaySort(`${sortedBy} First`);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      {selectedFlight ? (
        <>
          <p className="generalsans-medium text-[#ADADAD] mb-2" style={{ fontSize: '15px' }}>
            Selected flight
          </p>
          <div className="flight-section-label">
            <p className="font-bold mb-2 flex gap-1 items-center ">
              {fromCityName} <span className="span-bold">{fromAirportCode}</span> to {toCityName} <span className="span-bold mr-2">{toAirportCode}</span>
              <span>
                <AirplaneAscendSvg _width={20} _height={20} _color="#016E7F" />
              </span>
            </p>
          </div>
        </>
      ) : (
        <div className="position-sticky top-0 -mt-14 pt-[2.5rem] pb-2 bg-[#F9F9F9] z-1">
          <p className="generalsans-medium text-[#ADADAD] mb-2" style={{ fontSize: '15px' }}>
            Select your flight
          </p>

          <div className="flight-section-label ">
            <div className="flight-sort-container flex justify-between">
              <p className="span-bold mb-2 flex gap-1 items-center text-[22px] font-bold text-black-black-100">
                {fromCityName} <span className="font-bold text-secondary-secondary-100">{fromAirportCode}</span> to {toCityName}{' '}
                <span className="mr-2 font-bold text-secondary-secondary-100">{toAirportCode}</span>
                <span>
                  <AirplaneAscendSvg _width={20} _height={20} _color="#016E7F" />
                </span>
              </p>

              <div className="font-medium relative">
                <p className="flex items-center gap-2" style={{ fontFamily: 'General Sans' }} >
                  Sort by:{' '}
                  <span className="text-primary-primary-100 flex items-center gap-2 cursor-pointer relative" onClick={() => setShowFilters(!showFilters)}>
                    {oneWaySort}
                    <ChevronDownV2 _color="#016E7F" style={{ transform: showFilters ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all 0.2s ease-in-out' }} />
                  </span>
                </p>

                {showFilters && (
                  <div
                    onMouseLeave={() => setShowFilters(false)}
                    id="filters-holder"
                    className="position-absolute bg-body d-flex flex-column align-items-start justify-content-center"
                    style={{
                      left: 0,
                      right: 0,
                      marginRight: 'auto',
                      marginTop: '10px',
                      width: '100%',
                    }}
                  >
                    {FlightSortingOptions.map((option, index) => (
                      <div
                        key={index}
                        className="filters-opt"
                        style={{
                          fontFamily: 'General Sans',
                          fontWeight: '500',
                          paddingTop: '4px',
                        }}
                        onClick={() => {
                          setShowFilters(false);
                          setOneWaySort(option.fullLabel);
                          const filters = filterURL();
                          const departureUrl = departureURL();
                          const returnUrl = returnURL();
                          setSelectedFilter(option.value);
                          // navigate(urlGetParams + '&sortedBy=' + option.value + filters);
                          navigate(urlGetParams + (flightFilterType === 'departure' ? `&sortedBy=${option.value}${filters}${departureUrl}` : `&returnSortedBy=${option.value}${filters}${returnUrl}`));
                        }}
                      >
                        <p className="text-sm">{option.label}</p>
                        <p className="text-xs">{option.fullLabel}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <p className="generalsans-medium" style={{ fontSize: '13px', color: '#ADADAD' }}>
              Showing {resultsCount} result{resultsCount! > 1 ? 's' : ''}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default SelectedFlightLabel;
