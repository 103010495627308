import React, { useEffect, useRef, useState } from 'react';
import '../../../resources/css/search-filter.scss';
import { GalagoButton } from '../../../components/Button';
import SliderFilter from '../../../components/ui/slider-filter';
import { formatPriceNoRound } from '../../../utils/data.utils';
import { Star } from '../../../resources/svg/StarSvg';
import FilterCheckbox from '../../../components/ui/checkbox-filter';
import { HotelFiltersStore } from '../../../store/HotelFiltersStore';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CloseSvg } from '../../../resources/svg/CloseCircleSvg';
// import { url } from 'inspector';
export default function HotelFilters() {
  const navigate = useNavigate();
  const [SearchParams] = useSearchParams();
  const {
    setFilterKey,
    filterKey,
    priceRange,
    setSelectedPriceRange,
    selectedPriceRange,
    selectedPriceRangeLabel,
    setSelectedPriceRangeLabel,
    setSelectedRating,
    selectedRating,
    facilitiesList,
    selectedFacilities,
    setSelectedFacilities,
    bedPreferencesList,
    selectedBedPreferences,
    setSelectedBedPreferences,
    selectedFilterTags,
    setSelectedFilterTags,
    filterReset,
    setFilterReset,
  } = HotelFiltersStore();

  // Search Params
  const getKeyword = SearchParams.get('keyword');
  const getCheckInDate = SearchParams.get('checkInDate');
  const getCheckOutDate = SearchParams.get('checkOutDate');
  const getAdults = SearchParams.get('adults');
  const getRooms = SearchParams.get('rooms');
  const getLatitude = SearchParams.get('latitude');
  const getLongitude = SearchParams.get('longitude');
  const getFilterKey = SearchParams.get('filterKey');
  const getSortedBy = SearchParams.get('sortedBy');

  let urlGetParams = '';

  if (getKeyword) urlGetParams += `?keyword=${getKeyword}`;
  else if (getLongitude && getLatitude) {
    urlGetParams += `?longitude=${getLongitude}&latitude=${getLatitude}`;
  }
  urlGetParams += `&checkInDate=${getCheckInDate}`;
  urlGetParams += `&checkOutDate=${getCheckOutDate}`;
  urlGetParams += `&adults=${getAdults}`;
  urlGetParams += `&rooms=${getRooms}`;
  // urlGetParams += `&sortedBy=${getSortedBy} ?? 'LowestPrice'`;

  useEffect(() => {
    // For retaining the star rating of the hotel after reloading
    const getHotelStars = SearchParams.get('hotelStars');
    if (getHotelStars && getHotelStars.length > 0) {
      let selectedRating = getHotelStars.split('|').map(Number);
      setRating(selectedRating);
    }

    const priceRangeUrl = SearchParams.get('priceRange');
    if (priceRangeUrl && priceRangeUrl.length > 0) {
      const priceRange = priceRangeUrl.split('-');
      setSelectedPriceRange({ start: Number(priceRange[0]), end: Number(priceRange[1]) });
      setSelectedPriceRangeLabel({ start: Number(priceRange[0]), end: Number(priceRange[1]) });
      if (selectedPriceRange.start === Number(priceRange[0]) && selectedPriceRange.end === Number(priceRange[1])) {
        setSelectedFilterTags(['Price Range'], true);
      } else {
        setSelectedFilterTags([...selectedFilterTags, 'Price Range']);
      }
    }

    const filterKey = SearchParams.get('filterKey');
    if (filterKey !== '' && filterKey !== null) {
      setFilterKey(filterKey);
    }

    const hotelFacilities = SearchParams.get('hotelFacilities');
    if (hotelFacilities && hotelFacilities.length > 0) {
      const facilities = hotelFacilities.split('|');
      setSelectedFacilities(facilities);
    }

    const bedPreferences = SearchParams.get('bedPreferences');
    if (bedPreferences && bedPreferences.length > 0) {
      const selectedBedPreferences = bedPreferences.split('|');
      setSelectedBedPreferences(selectedBedPreferences);
    }

    // (async () => {
    //   await resetFilters();
    // })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // UI side only
  const [isFilterChanged, setIsFilterChanged] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [hasShownTooltip, setHasShownTooltip] = useState(false);
  const [isGradientVisible, setIsGradientVisible] = useState(false);
  const searchFilterContainerRef = useRef<HTMLDivElement | null>(null);
  const hotelFacilitiesDivHolderRef = useRef<HTMLDivElement | null>(null);
  const bedPreferencesDivHolderRef = useRef<HTMLDivElement | null>(null);
  const [showAllBedPreferences, setShowAllBedPreferences] = useState(false);
  const [showAllHotelFacilities, setShowAllHotelFacilities] = useState(false);

  // rating
  const [rating, setRating] = useState<number[]>([]);

  // Price Range Slider
  const handleRangeChange = (newValue: number[]): void => {
    setSelectedPriceRange({ start: newValue[0], end: newValue[1] });
    setSelectedPriceRangeLabel({ start: newValue[0], end: newValue[1] });
    setIsFilterChanged(true);
    setSelectedFilterTags([...selectedFilterTags, 'Price Range']);
  };

  // For the tooltip on apply button
  useEffect(() => {
    if (isFilterChanged && !hasShownTooltip) {
      setShowTooltip(true);
      setHasShownTooltip(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterChanged]);

  useEffect(() => {
    const container = searchFilterContainerRef.current;

    const handleScroll = () => {
      const scrollPosition = container!.scrollTop + container!.offsetHeight;
      const containerHeight = container!.scrollHeight;

      if (scrollPosition >= containerHeight) {
        setIsGradientVisible(true);
      } else {
        setIsGradientVisible(false);
      }
    };

    if (container) {
      container!.addEventListener('scroll', handleScroll);
    }

    if (showAllHotelFacilities) {
      container!.scrollTop = container!.scrollHeight;
    }

    // Clean up the event listener
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [showAllHotelFacilities]);

  const starRatingList = [{ label: '1' }, { label: '2' }, { label: '3' }, { label: '4' }, { label: '5' }];

  const handleShowHotelFacilities = () => {
    setShowAllHotelFacilities((prevShowAll) => !prevShowAll);
  };

  const handleShowBedPreferences = () => {
    setShowAllBedPreferences((prevShowAll) => !prevShowAll);
  };

  const handleRating = (ids: number[]) => {
    setIsFilterChanged(true);
    setRating((prevRating) => {
      if (prevRating.includes(ids[0])) {
        return prevRating.filter((id: any) => id !== ids[0]);
      } else {
        return [...prevRating, ...ids];
      }
    });
  };

  const ratingString = rating.join('|');
  useEffect(() => {
    setSelectedRating(ratingString);
    if (rating.length !== 0) {
      setSelectedFilterTags([...selectedFilterTags, 'Star Rating']);
    } else {
      setSelectedFilterTags(['Star Rating'], true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratingString]);

  // const resetFilters = useCallback() => {
  //   setSelectedPriceRange({ start: priceRange.start, end: priceRange.end });
  //   setSelectedPriceRangeLabel({ start: priceRange.start, end: priceRange.end });
  //   setRating([]);
  //   setIsFilterChanged(false);
  //   resetSliders();
  //   resetHotelFacilities();
  //   resetBedPreferences();
  //   setSelectedFilterTags(['Price Range'], true);
  //   let url = `/hotel/results${urlGetParams}`;
  //   navigate(url);
  // };

  const resetFilters = () => {
    setSelectedPriceRange({ start: priceRange.start, end: priceRange.end });
    setSelectedPriceRangeLabel({ start: priceRange.start, end: priceRange.end });
    setRating([]);
    setIsFilterChanged(false);
    resetSliders();
    resetHotelFacilities();
    resetBedPreferences();
    setSelectedFilterTags(['Price Range'], true);
    let url = `/hotel/results${urlGetParams}`;
    navigate(url);
    setShowTooltip(false);
  };

  useEffect(() => {
    setFilterReset(resetFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterTags]); //

  const resetBedPreferences = () => {
    // eslint-disable-next-line
    bedPreferencesList.map((bedPreferences: { [index: string]: any }) => {
      if (selectedBedPreferences.includes(bedPreferences.toString())) {
        selectedBedPreferences.splice(selectedBedPreferences.indexOf(bedPreferences.toString()), 1);
      }
    });
    bedPreferencesDivHolderRef.current!.querySelectorAll('input').forEach((input: HTMLInputElement) => {
      input.checked = false;
    });
    setSelectedBedPreferences([]);
  };

  const resetHotelFacilities = () => {
    // eslint-disable-next-line
    facilitiesList.map((facilities: { [index: string]: any }) => {
      if (selectedFacilities.includes(facilities.toString())) {
        selectedFacilities.splice(selectedFacilities.indexOf(facilities.toString()), 1);
      }
    });
    hotelFacilitiesDivHolderRef.current!.querySelectorAll('input').forEach((input: HTMLInputElement) => {
      input.checked = false;
    });
    setSelectedFacilities([]);
  };

  const resetSliders = () => {
    // search div with data-key attribute inside searchFilterContainerRef
    const allRangeInputsDivs = searchFilterContainerRef.current!.querySelectorAll('.rs-slider-handle');
    // loop through allRangeInputsDiv and reset the values of the sliders
    allRangeInputsDivs.forEach((div) => {
      // get data-key attribute value
      const key = div.getAttribute('data-key');
      // get the input element inside the div
      const input = div.querySelector('input');
      // get the aria-valuemax and aria-valuemin attribute of the input element
      const min = input!.getAttribute('aria-valuemin');
      const max = input!.getAttribute('aria-valuemax');
      // get the sliderbars (the teal colored bars) to reset position when clear all button is pressed
      const sliderBars = document.querySelectorAll('.rs-slider-progress-bar');

      // reset the rs-slider-handle style to 0% if the key is start, 100% if the key is end
      if (div instanceof HTMLDivElement) {
        if (key === 'start') {
          div.style.left = '0%';

          //loop the sliderBars to access each style to leftmost.
          sliderBars.forEach((sliderBar) => {
            (sliderBar as HTMLElement).style.left = '0%';
          });
        }
        if (key === 'end') {
          div.style.left = '100%';
        }
      }
      // set the aria-valuenow and value of the input element to the default value
      input!.setAttribute('aria-valuenow', priceRange[key as 'start' | 'end'].toString());
      input!.setAttribute('value', priceRange[key as 'start' | 'end'].toString());

      // reset the rs-slider-handle data-range-key attribute to the default value
      div.setAttribute('data-range', `${min},${max}`);

      // get the upper sibling of this div
      const upperSibling = div.previousElementSibling as HTMLDivElement;
      // if the upper sibling has class rs-slider-bar then continue then reset the teal color the slider bar
      if (upperSibling.classList.contains('rs-slider-bar')) {
        // get the child div of rs-slider-bar
        const childDiv = upperSibling.querySelector('div');
        // change the style width to 100% of childDiv
        childDiv!.style.width = '100%';
      }
    });
  };

  useEffect(() => {
    if (selectedBedPreferences.length === 0) {
      setSelectedFilterTags(['Bed Preferences'], true);
    } else {
      setSelectedFilterTags([...selectedFilterTags, 'Bed Preferences']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBedPreferences]);

  useEffect(() => {
    if (selectedFacilities.length === 0) {
      setSelectedFilterTags(['Hotel Facilities & Services'], true);
    } else {
      setSelectedFilterTags([...selectedFilterTags, 'Hotel Facilities & Services']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFacilities]);

  return (
    <div className="search-filter-container-hotel search-filter-hotel relative w-100 max-w-[360px] bg-red">
      <div className="search-header">
        <p style={{ fontSize: '17px', marginRight: '28px' }}>Filters</p>
        <div className="flex gap-1">
          {isFilterChanged && (
            <GalagoButton
              onClick={() => {
                // resetFilters();
                filterReset();
                setShowTooltip(false);
              }}
              className="clear-btn"
              label="Clear All"
              btn="primary"
            />
          )}
          <GalagoButton
            label="Apply"
            className="apply-btn font-bold"
            onClick={() => {
              setShowTooltip(false);
              // Prevent the filterkey from being undefined
              urlGetParams += `&filterKey=${filterKey === undefined || filterKey === null ? getFilterKey : filterKey}`;
              if (getSortedBy) {
                urlGetParams += `&sortedBy=${getSortedBy}`;
              }
              if (selectedPriceRange.start !== 0 && selectedPriceRange.end !== 0) {
                urlGetParams += `&priceRange=${selectedPriceRange.start}-${selectedPriceRange.end}`;
              }
              if (selectedRating !== '') {
                urlGetParams += `&hotelStars=${selectedRating}`;
              }

              if (selectedFacilities.length > 0) {
                let selectedFacilitiesUrl = selectedFacilities.join('|');
                urlGetParams += `&hotelFacilities=${selectedFacilitiesUrl}`;
              }

              if (selectedBedPreferences.length > 0) {
                let selectedBedPreferencesUrl = selectedBedPreferences.join('|');
                urlGetParams += `&bedPreferences=${selectedBedPreferencesUrl}`;
              }

              let url = `/hotel/results${urlGetParams}`;
              navigate(url);
            }}
            disabled={!isFilterChanged}
          />
        </div>
      </div>

      <div ref={searchFilterContainerRef} className="filter-scroll">
        <div className="search-content">
          <div className={selectedFilterTags.length > 0 ? 'filter-tags-container pb-3git pb-4 mb-4' : ''}>
            {selectedFilterTags.map((tag, index) => (
              <div key={index} className="filter-tag" style={{ display: selectedFilterTags.includes(tag) ? 'flex' : 'none' }}>
                <p>{tag}</p>
                <div
                  onClick={() => {
                    setIsFilterChanged(true);
                    setSelectedFilterTags([tag], true);
                    if (tag === 'Price Range') {
                      setSelectedPriceRange({ start: priceRange.start, end: priceRange.end });
                      setSelectedPriceRangeLabel({ start: priceRange.start, end: priceRange.end });
                      resetSliders();
                    }
                    if (tag === 'Star Rating') {
                      setSelectedRating('');
                      setRating([]);
                    }

                    if (tag === 'Bed Preferences') {
                      resetBedPreferences();
                      setSelectedBedPreferences([]);
                    }

                    if (tag === 'Hotel Facilities & Services') {
                      resetHotelFacilities();
                      setSelectedFacilities([]);
                    }
                  }}
                  className="cursor-pointer"
                >
                  <CloseSvg />
                </div>
              </div>
            ))}
          </div>

          <div className="price-range-container">
            <p className="satoshi-semibold text-xl text-[#333333] ">Price Range</p>
            <p className="text-grey generalsans-medium text-sm mt-0">Set price range per night</p>
            <div className="slider-container my-4 ms-1 me-1.5">
              {priceRange.start !== 0 && priceRange.end !== 0 && (
                <SliderFilter
                  min={priceRange.start}
                  max={priceRange.end}
                  defaultValue={[selectedPriceRange.start, selectedPriceRange.end]}
                  tooltip={false}
                  step={500}
                  constraint={([start, end]) => {
                    const minSteps = priceRange.end * 0.1;
                    return start < end && end - start >= minSteps;
                  }}
                  onChange={handleRangeChange}
                />
              )}
            </div>

            <div className="price-range-btns d-flex justify-content-between generalsans-medium">
              <div className="price-range-btn">
                <p className="text-secondary-secondary-100">Minimum</p>
                <p className="text-black-black-100">PHP {selectedPriceRangeLabel.start !== 0 ? formatPriceNoRound(selectedPriceRangeLabel.start) : formatPriceNoRound(priceRange.start)}</p>
              </div>
              <div className="price-range-btn">
                <p className="text-secondary-secondary-100">Maximum</p>
                <p className="text-black-black-100">PHP {selectedPriceRangeLabel.end !== 0 ? formatPriceNoRound(selectedPriceRangeLabel.end) : formatPriceNoRound(priceRange.end)}</p>
              </div>
            </div>
          </div>

          <div className="star-rating-container py-3 pb-4">
            <p className="text-xl generalsans-semibold mb-[12px] text-[#333333]">Star Rating</p>
            <div className="ratings-container mt-3">
              {starRatingList.map((rate) => (
                <div className={rating.includes(parseInt(rate.label)) ? 'rating active' : 'rating'} onClick={() => handleRating([parseInt(rate.label)])}>
                  <p className="text-base"> {rate.label}</p>
                  <Star _color="#FFB400" _width={14} _height={14} />
                </div>
              ))}
            </div>
          </div>

          <div className="star-rating-container py-3" ref={bedPreferencesDivHolderRef}>
            <p className="text-xl generalsans-semibold mb-[12px] text-[#333333]">Bed Preferences</p>

            <div className="text-base">
              {bedPreferencesList.map((bedPreferences: { [index: string]: any }, index: React.Key) => {
                if (Number(index) > 2 && !showAllBedPreferences) return null;

                return (
                  <FilterCheckbox
                    key={index}
                    id={`${bedPreferences}`}
                    label={`${bedPreferences}`}
                    value={`${bedPreferences}`}
                    defaultChecked={selectedBedPreferences.includes(bedPreferences.toString())}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const isChecked = event.target.checked;
                      const bedPreferenceValue = event.target.value;
                      setIsFilterChanged(true);

                      if (!isChecked) {
                        setSelectedBedPreferences([bedPreferenceValue], true);
                      }

                      if (!selectedBedPreferences.includes(bedPreferenceValue) && isChecked) {
                        setSelectedBedPreferences([...selectedBedPreferences, bedPreferenceValue]);
                      }

                      // if (isChecked) {
                      //   setSelectedFilterTags([...selectedFilterTags, 'Bed Preferences']);
                      // }
                    }}
                  />
                );
              })}
            </div>

            {bedPreferencesList.length > 2 && (
              <GalagoButton
                label={showAllBedPreferences ? `Show Less` : `Show all (${bedPreferencesList.length})`}
                btn="link"
                className="!justify-start !px-0 w-max"
                onClick={handleShowBedPreferences}
              />
            )}
          </div>

          <div className="airlines-container py-3" ref={hotelFacilitiesDivHolderRef}>
            <p className="text-xl generalsans-semibold mb-[12px] text-[#333333]">Hotel Facilities & Services</p>

            <div className="text-base">
              {facilitiesList.map((facilities: { [index: string]: any }, index: React.Key) => {
                // display only five facilities first if the user clicks show all facilities, display all
                if (Number(index) > 4 && !showAllHotelFacilities) return null;
                return (
                  <FilterCheckbox
                    key={index}
                    id={`${facilities}`}
                    label={`${facilities}`}
                    value={`${facilities}`}
                    defaultChecked={selectedFacilities.includes(facilities.toString())}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const isChecked = event.target.checked;
                      const facilityValue = event.target.value;
                      setIsFilterChanged(true);

                      // if the checkbox is unchecked, check if the facility is in the selectedFacilities then remove it
                      if (!isChecked) {
                        setSelectedFacilities([facilityValue], true);
                      }

                      // if facility value is not in the selectedFacilities, add it
                      if (!selectedFacilities.includes(facilityValue) && isChecked) {
                        setSelectedFacilities([...selectedFacilities, facilityValue]);
                      }

                      // if (isChecked) {
                      //   setSelectedFilterTags([...selectedFilterTags, 'Hotel Facilities & Services']);
                      // }
                    }}
                  />
                );
              })}
            </div>

            {facilitiesList.length > 4 && (
              <GalagoButton
                label={showAllHotelFacilities ? `Show Less` : `Show all (${facilitiesList.length})`}
                btn="link"
                className="!justify-start !px-0 w-max"
                onClick={handleShowHotelFacilities}
              />
            )}
          </div>

          <div className={`bottom-gradient  ${isGradientVisible ? 'show' : ''}`}></div>
          <div className={`big-tooltip absolute top-0 right-[-199px] flex flex-col z-10 ${showTooltip ? 'show' : ''}`}>
            <p>
              Click here to apply your <br /> search filters!
            </p>
            <GalagoButton label="Got it!" className="btn-tooltip" onClick={(): void => setShowTooltip(false)} />
          </div>
        </div>
      </div>
    </div>
  );
}
