import React from 'react';

export const PaymentMethodLogo = ({ method }: { method: 'gcash' | 'grab_pay' | 'paymaya' | 'dob_ubp' | 'dob' | 'others' }) => {
  return (
    <>
      {method === 'gcash' ? <img src="/img/gcash.svg" alt="" style={{ width: '80px' }} /> : null}
      {method === 'grab_pay' ? <img src="/img/grabpay.svg" alt="" style={{ width: '80px' }} /> : null}
      {method === 'paymaya' ? <img src="/img/paymaya.svg" alt="" style={{ width: '80px' }} /> : null}
      {method === 'dob_ubp' ? <img src="/img/unionbank.svg" alt="" style={{ width: '80px' }} /> : null}
      {method === 'dob' ? <span className="text-xs">Direct Online Banking</span> : null}
      {method === 'others' ? <span className="text-md"> Others</span> : null}
    </>
  );
};
